import { TokenInfo } from 'src/models/admin/type';
import { AuthorityType } from 'src/store/slice/adminSlice';

export const extractTokenInfo = (token: string): TokenInfo => {
  const encoded = token.match(/\.(.*)\./gi);
  if (encoded === null) {
    throw 'decode error.';
  }
  return JSON.parse(Buffer.from(encoded[0], 'base64').toString());
};

export const createAuthorities = (token: string) => {
  const authorities = extractTokenInfo(token).authorities;
  const newObj: AuthorityType = {
    userView: authorities.UserView,
    userEdit: authorities.UserEdit,
    gymView: authorities.GymView,
    reward: authorities.Reward,
    news: authorities.News,
    log: authorities.Log,
    campaign: authorities.Campaign,
  };
  return newObj;
};

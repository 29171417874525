import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAuthorities } from 'src/models/admin/function';

export type AuthorityType = {
  userView: number;
  userEdit: number;
  gymView: number;
  reward: number;
  news: number;
  log: number;
  campaign: number;
};

export type AdminState = {
  authorities: AuthorityType;
  isLoggedIn: boolean | null;
};

const initialState: AdminState = {
  authorities: {
    userView: 0,
    userEdit: 0,
    gymView: 0,
    reward: 0,
    news: 0,
    log: 0,
    campaign: 0,
  },
  isLoggedIn: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    reset: (state) => {
      //stateのもう一つ深い階層まで指定しないと反映されない
      state.authorities = {
        userView: 0,
        userEdit: 0,
        gymView: 0,
        reward: 0,
        news: 0,
        log: 0,
        campaign: 0,
      };
      state.isLoggedIn = false;
    },
    loggedIn: (state, action: PayloadAction<{ accessToken: string }>) => {
      const authorities = createAuthorities(action.payload.accessToken);
      state.authorities = authorities;
      state.isLoggedIn = true;
    },
  },
});

export const adminReducer = adminSlice.reducer;

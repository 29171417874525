import { Building, CalendarSearch, Contact, Gem, Gift, Ticket, Users, Wrench } from 'lucide-react';
import { ReactNode } from 'react';
import { AuthorityType } from 'src/store/slice/adminSlice';

export type PageValue = { name: string; icon: ReactNode; href: string; authority: keyof AuthorityType | null };
type PageKey = 'users' | 'gyms' | 'partners' | 'plans' | 'equipments' | 'rewards' | 'campaigns' | 'news' | 'login';
type PageMap = Record<PageKey, PageValue>;

export const PAGE_MAP: PageMap = {
  users: {
    name: '会員管理',
    icon: <Users />,
    href: '/users',
    authority: 'userView',
  },
  gyms: {
    name: 'ジム管理',
    icon: <Building />,
    href: '/gyms',
    authority: 'gymView',
  },
  partners: {
    name: 'パートナー管理',
    icon: <Contact />,
    href: '/gyms/partners',
    authority: 'gymView',
  },
  rewards: {
    name: 'リワード管理',
    icon: <Gem />,
    href: '/rewards',
    authority: 'reward',
  },
  plans: {
    name: 'プラン管理',
    icon: <Ticket />,
    href: '/gyms/plans',
    authority: 'gymView',
  },
  equipments: {
    name: '設備管理',
    icon: <Wrench />,
    href: '/gyms/equipment',
    authority: 'gymView',
  },
  campaigns: {
    name: 'キャンペーン管理',
    icon: <Gift />,
    href: '/campaigns',
    authority: 'campaign',
  },
  news: {
    name: 'お知らせ',
    icon: <CalendarSearch />,
    href: '/news',
    authority: 'news',
  },
  login: {
    name: '',
    icon: null,
    href: '/auth/login',
    authority: null,
  },
};

export const PUBLIC_ROUTES = [PAGE_MAP.login.href];

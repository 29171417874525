import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Layout from 'src/components/Layout';
import { Button } from 'src/components/ui/button';
import { STATUS_CODE } from 'src/constant/api';
import { isAcceptableError } from 'src/utils/errorValidate';

type Props = {
  children: ReactNode;
};

type ErrorContent = Record<number, { title: string; message: string }>;
const ERROR_CONTENT: ErrorContent = {
  [STATUS_CODE.FORBIDDEN]: {
    title: 'アクセスが拒否されました',
    message: 'このページにアクセスするための十分な権限を持っていません。',
  },
  [STATUS_CODE.NOT_FOUND]: {
    title: 'ページが見つかりませんでした',
    message: 'お探しのページはURLが間違っているか、削除された可能性があります。',
  },
  [STATUS_CODE.SERVER_ERROR]: {
    title: 'サーバーエラーが発生しました',
    message: 'しばらくしてから再度お試しください。',
  },
};

const Fallback = ({ error }: FallbackProps) => {
  const statusCode = isAcceptableError(error) ? error.statusCode ?? STATUS_CODE.SERVER_ERROR : STATUS_CODE.SERVER_ERROR;
  const errorContent = ERROR_CONTENT[statusCode] ?? ERROR_CONTENT[STATUS_CODE.SERVER_ERROR];

  const handleTopPageBackClick = () => {
    location.href = '/';
  };

  return (
    <Layout>
      <div className="flex h-full w-full items-center justify-center ">
        <div className="align-center w-full max-w-[640px] flex-col justify-center space-y-6  px-10">
          <h3 className="text-center text-[28px] font-bold text-brand-900">{errorContent.title}</h3>
          <p className="text-center text-brand-900">{errorContent.message}</p>
          <div className="text-center">
            <Button onClick={handleTopPageBackClick} variant="secondary" className="mx-auto">
              トップページに戻る
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const ErrorBoundaryProvider = ({ children }: Props) => {
  const router = useRouter();
  return (
    <ErrorBoundary FallbackComponent={Fallback} resetKeys={[router.pathname]}>
      {children}
    </ErrorBoundary>
  );
};

import { LogOut } from 'lucide-react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { MenuItem } from 'src/components/MenuItem';
import { PAGE_MAP, PageValue } from 'src/constant/page';
import { useLogout } from 'src/hooks/useAuth';
import { authoritySelector } from 'src/store/selector/adminSelector';

const LOGOUT_ITEM = {
  name: 'ログアウト',
  icon: <LogOut />,
  href: '/logout',
  authority: null,
};

const SIDEBAR_ITEMS: { sectionTitle: string; items: PageValue[] }[] = [
  {
    sectionTitle: '',
    items: [PAGE_MAP.users],
  },
  {
    sectionTitle: 'ジム運営・設定',
    items: [PAGE_MAP.partners, PAGE_MAP.gyms, PAGE_MAP.plans, PAGE_MAP.equipments],
  },
  {
    sectionTitle: '運用',
    items: [PAGE_MAP.campaigns, PAGE_MAP.rewards, PAGE_MAP.news],
  },
  {
    sectionTitle: 'その他の管理',
    items: [LOGOUT_ITEM],
  },
];

export const Sidebar = () => {
  const authorityState = useSelector(authoritySelector);
  const router = useRouter();
  const { handleLogout } = useLogout();

  const handleMenuItemClick = async (href: string) => {
    if (href === LOGOUT_ITEM.href) {
      handleLogout();
      return;
    }

    router.push(href);
  };

  const isSelected = (item: PageValue) => item.href === router.pathname;

  const isMenuItemVisible = (item: PageValue) => {
    if (!item.authority) {
      return true;
    }
    return !!authorityState[item.authority];
  };

  const getVisibleMenuItems = (items: PageValue[]) => items.filter(isMenuItemVisible);

  return (
    <div className="sticky top-header h-sidebar w-[240px] overflow-auto py-14 pt-8">
      <div className="space-y-6 p-[5px]">
        {SIDEBAR_ITEMS.map(({ sectionTitle, items }) => {
          const visibleMenuItems = getVisibleMenuItems(items);
          if (!visibleMenuItems.length) {
            return null;
          }
          return (
            <div key={sectionTitle} className="space-y-1.5">
              {sectionTitle && <div className="px-2 py-1.5 text-sm font-bold text-brand-700">{sectionTitle}</div>}
              {visibleMenuItems.map((item) => (
                <MenuItem
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  isSelected={isSelected(item)}
                  onClick={() => handleMenuItemClick(item.href)}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
